/* eslint-disable max-classes-per-file */
import type { RumInitConfiguration, RumGlobal } from '@datadog/browser-rum';
import type { InitConfiguration } from '@datadog/browser-core';
import { LogsInitConfiguration, datadogLogs } from '@datadog/browser-logs';
import { useEffect, useRef } from 'react';
import { AxiosResponse } from 'axios';

import { ClientOnly } from 'lib/decorators';
import { IS_CLIENT_SIDE } from 'constants/constants';
import { Override } from 'types/objectTypes';

const disableError = (error: ErrorEvent) => {
  error.preventDefault();
  error.stopPropagation();

  return true;
};

const shouldStopErrorPropagating = (event: ErrorEvent) => {
  const { message, error } = event;

  if (!message || !error) {
    return disableError(event);
  }

  if (error.message?.startsWith('Minified React error')
  || message.startsWith('Minified React error')) {
    return disableError(event);
  }

  return false;
};

class DataDogRumWrapper<T extends RumGlobal> {
  private ddRum: T | undefined ;

  public setInstance(ddRum: T) {
    this.ddRum = ddRum;
  }

  public getInstance() {
    return this.ddRum;
  }
}

const ddRumWrapper = new DataDogRumWrapper();

const logError = (error: Error | string | AxiosResponse<any>) => {
  if (!error) {
    return datadogLogs.logger.error('Unknown error');
  }

  if (typeof error === 'string') {
    return datadogLogs.logger.error(error);
  }

  const axiosError = error as AxiosResponse<any>;

  if (axiosError.data) {
    return datadogLogs.logger.error(axiosError.data);
  }

  const commonError = error as Error;

  datadogLogs.logger.error(commonError.message || '', {
    error: { stack: commonError.stack },
  });
};

/**
 * @function
 * @param {ErrorEvent} error - if we need to remove errors with hydration we can do this
 * with stopPropagation and stopImmediatePropagation in condition that error.message includes word
 * "hydration"
 */
const onError = (error: ErrorEvent) => {
  if (!error?.error) {
    return;
  }

  const isStopped = shouldStopErrorPropagating(error);

  if (isStopped) {
    return;
  }

  logError(error.error);
};

export const useInitDataDog = () => {
  const isListenerSet = useRef<boolean>(false);

  useEffect(() => {
    const isDataDogNotRequired = !process.env.NEXT_PUBLIC_DD_APPLICATION_ID
      || !process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN
      || !process.env.NEXT_PUBLIC_DD_SITE;
    const isNotCPDomain = window.location.origin !== process.env.NEXT_PUBLIC_BASE_SERVER_URL;

    if (isDataDogNotRequired || isNotCPDomain) {
      return;
    }

    const commonConfig: Override<
    InitConfiguration,
    { beforeSend?: RumInitConfiguration['beforeSend'] & LogsInitConfiguration['beforeSend'] }
    > = {
      clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
      site: process.env.NEXT_PUBLIC_DD_SITE,
      service: process.env.NEXT_PUBLIC_DD_SERVICE,
      env: process.env.NEXT_PUBLIC_DD_ENV,
      version: process.env.NEXT_PUBLIC_DD_VERSION,
      sampleRate: (+process.env.NEXT_PUBLIC_DD_SAMPLE_RATE!) || 100,
    };

    if (process.env.NEXT_PUBLIC_DD_IS_RUM_ENABLED === 'true') {
      import('@datadog/browser-rum').then(({ datadogRum }) => {
        datadogRum.init({
          ...commonConfig,
          applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID!,
          sessionReplaySampleRate: (+process.env.NEXT_PUBLIC_DD_SESSION_REPLAY_SAMPLE_RATE!) || 20,
          trackInteractions: (process.env.NEXT_PUBLIC_DD_TRACK_INTERACTIONS)
            ? process.env.NEXT_PUBLIC_DD_TRACK_INTERACTIONS === 'true'
            : undefined,
          trackResources: (process.env.NEXT_PUBLIC_DD_TRACK_RESOURCES)
            ? process.env.NEXT_PUBLIC_DD_TRACK_RESOURCES === 'true'
            : undefined,
          trackLongTasks: (process.env.NEXT_PUBLIC_DD_TRACK_LONG_TASKS)
            ? process.env.NEXT_PUBLIC_DD_TRACK_LONG_TASKS === 'true'
            : undefined,
          defaultPrivacyLevel: process.env.NEXT_PUBLIC_DD_DEFAULT_PRIVACY_LEVEL as any,
        });

        datadogRum.startSessionReplayRecording();
        ddRumWrapper.setInstance(datadogRum);
      });
    }

    datadogLogs.init({
      ...commonConfig,
      forwardConsoleLogs: ['error', 'warn'],
      beforeSend: (event) => {
        const baseUrl = process.env.NEXT_PUBLIC_BASE_SERVER_URL!;

        if (event.message
          && (event.message.startsWith('console error: UnknownError: The user denied permission to access the database.')
            || event.message.startsWith('console error: Failed to read the \'localStorage\' property from')
            || event.message.startsWith('console error: localStorage error use cookies')
            || event.message.includes('clarity.ms/collect')
            || event.message.startsWith('Script error.')
            || event.message.startsWith('UnknownError: Internal error.')
            || event.message.includes('IDBFactory.open()')
            || event.message.startsWith('InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.')
            || (event.message.includes('Fetch error')
              && !(event.message.match(new RegExp(`Fetch error .{2,7} (${baseUrl}).*`))))
            || (event.message.includes('XHR error')
              && !(event.message.match(new RegExp(`XHR error .{2,7} (${baseUrl}).*`))))
            || event.message.toLowerCase().includes('database')
            || event.message.startsWith('console error: cookies are unavailable'))) {
          return false;
        }
      },
    });

    return () => {
      window.removeEventListener('error', onError);
      isListenerSet.current = false;
    };
  }, []);

  if (IS_CLIENT_SIDE && !isListenerSet.current) {
    window.addEventListener('error', onError);
    isListenerSet.current = true;
  }
};

export class DataDogWrapper {
  @ClientOnly()
  public static captureException(e: Error | AxiosResponse<any>, context?: any) {
    const datadogRum = ddRumWrapper.getInstance();

    logError(e);

    if (!datadogRum) {
      return;
    }

    if ((e as AxiosResponse<any>)?.data) {
      datadogRum.addError((e as AxiosResponse<any>).data, context);
    } else {
      datadogRum.addError(e, context);
    }
  }

  @ClientOnly()
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public static captureLog(message: any, _?: any) {
    datadogLogs.logger.log(message);
  }

  @ClientOnly()
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public static captureWarn(message: any, _?: any) {
    datadogLogs.logger.warn(message);
  }
}
